"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setCookie = void 0;
const setCookie = (name, value, expirationDays) => {
    let maxAge = "";
    if (expirationDays) {
        maxAge = `; max-age=${expirationDays * 24 * 60 * 60}`;
    }
    const host = window.location.host;
    const domain = (host.match(/(reclaim\.\w+)$/i) || [host])[0];
    const suffix = `; domain=${domain}; path=/; samesite=lax; `;
    const cookieString = `${name}=${value || ""}${maxAge}${suffix}`;
    document.cookie = cookieString;
};
exports.setCookie = setCookie;
