"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initGtagConsent = void 0;
const initGtagConsent = () => {
    if (typeof window.gtag !== "undefined") {
        window.gtag("consent", "default", {
            ad_storage: "denied",
            ad_user_data: "denied",
            ad_personalization: "denied",
            analytics_storage: "denied",
            wait_for_update: 1000,
        });
        if (typeof window.Osano !== "undefined") {
            window.Osano("onConsentSaved", (consent) => {
                window.gtag("consent", "update", {
                    ad_user_data: consent.MARKETING == "ACCEPT" ? "granted" : "denied",
                    ad_personalization: consent.MARKETING == "ACCEPT" ? "granted" : "denied",
                    ad_storage: consent.MARKETING == "ACCEPT" ? "granted" : "denied",
                    analytics_storage: consent.ANALYTICS == "ACCEPT" ? "granted" : "denied",
                });
            });
        }
    }
};
exports.initGtagConsent = initGtagConsent;
