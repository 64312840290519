"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setCookieOrUpdateExpiration = void 0;
const getCookie_1 = require("./getCookie");
const setCookie_1 = require("./setCookie");
const setCookieOrUpdateExpiration = (name, value, expirationDays) => {
    const existingCookieValue = (0, getCookie_1.getCookie)(name);
    if (!existingCookieValue)
        (0, setCookie_1.setCookie)(name, value, expirationDays);
    else
        (0, setCookie_1.setCookie)(name, existingCookieValue, expirationDays);
};
exports.setCookieOrUpdateExpiration = setCookieOrUpdateExpiration;
